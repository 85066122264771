var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "account-main my-5 my-md-6" },
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center" } },
        [
          _c("b-col", { staticClass: "col-auto" }, [
            _c("h3", { staticClass: "display-4 text-primary heading" }, [
              _vm._v("Insurance Documents"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "b-toast",
        {
          attrs: {
            id: "alertToast",
            variant: "danger",
            "no-close-button": "",
            "auto-hide-delay": "2500",
            toaster: "b-toaster-top-center",
            solid: "",
          },
        },
        [_vm._v(" " + _vm._s(_vm.toastMessage) + " ")]
      ),
      _c(
        "b-row",
        {
          staticClass: "mt-6",
          attrs: { "align-h": "center", "align-v": "center" },
        },
        [
          _c(
            "b-col",
            { staticClass: "mb-5 mx-4 mx-md-0 mb-md-0" },
            [
              _c(
                "b-card",
                { staticClass: "pt-3 insurance-card" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "pt-3 text-center text-md-left" },
                        [_c("h2", [_vm._v("HSA Receipt")])]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right",
                          attrs: { cols: "12", md: "4", lg: "3" },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: { click: _vm.openHSADialog },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "px-3",
                                  attrs: {
                                    variant: "info",
                                    pill: "",
                                    size: "lg",
                                    block: "",
                                  },
                                },
                                [_vm._v("Download")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "pt-3 insurance-card" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "pt-3 text-center text-md-left" },
                        [_c("h2", [_vm._v("FSA Receipt")])]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right",
                          attrs: { cols: "12", md: "4", lg: "3" },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: { click: _vm.openFSADialog },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "px-3",
                                  attrs: {
                                    variant: "info",
                                    pill: "",
                                    size: "lg",
                                    block: "",
                                  },
                                },
                                [_vm._v("Download")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "pt-3 insurance-card" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "pt-3 text-center text-md-left" },
                        [_c("h2", [_vm._v("NDC Form")])]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "text-right",
                          attrs: { cols: "12", md: "4", lg: "3" },
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "javascript:;" },
                              on: { click: _vm.openNDCDialog },
                            },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "px-3",
                                  attrs: {
                                    variant: "info",
                                    pill: "",
                                    size: "lg",
                                    block: "",
                                  },
                                },
                                [_vm._v("Download")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("p", { staticClass: "insurance-info px-4 pt-4" }, [
                _vm._v(
                  " We understand that some of our patients may be looking to submit their medical expenses to their insurance provider for reimbursement. To assist with this process, we have made it easy for you to access the necessary documents, such as the Health Savings Account (HSA) and Flexible Spending Account (FSA) and National Drug Codes (NDC), directly from our website. By accessing these documents and submitting them to your insurance provider, you may be able to recoup some of the costs of your medical treatment. Please note that the submission and management of these claims is the patient's responsibility. "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("HSAReceiptDialog", {
        ref: "HSAReceiptDialog",
        attrs: { id: _vm.id, name: _vm.name },
      }),
      _c("FSAReceiptDialog", {
        ref: "FSAReceiptDialog",
        attrs: { "row-data": _vm.fsaData },
        on: { "show-toast": _vm.showToast },
      }),
      _c("NDCFormDialog", {
        ref: "NDCFormDialog",
        attrs: { "row-data": _vm.fsaData },
        on: { "show-toast": _vm.showToast },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }